<template>
<v-container class="my-5">
  <v-card>
    <v-dialog v-model="dialogDelete">
    <v-card>

    <v-toolbar
        flat
      >
        <v-toolbar-title>Detail Bukti wwwPotong PPh</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>         
    </v-toolbar>
    <v-divider></v-divider>
    <v-row no-gutters class="px-5">
      <v-col
        :cols="4"
      >
        <v-card ref="form" class="my-5">
        <v-card-text>
          <v-toolbar flat>
              <v-toolbar-title>Dokumen Bukti Potong</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-text-field
            v-model="dokumenBupot.bupot_number"
            label="Nomor Dok. BuktiPotong"
            filled
            readonly
            class="mt-5"
          ></v-text-field>
          <v-text-field
            v-model="dokumenBupot.bupot_date"
            label="Tanggal Bukti Potong"
            filled
            readonly
          ></v-text-field>
          <vuetify-money
            v-model="dokumenBupot.dpp_amount"
            label="Jumlah Penghasilan Bruto"
            v-bind:placeholder="placeholder"
            readonly
            disabled
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />  
          <vuetify-money
            v-model="dokumenBupot.percentage"
            label="Tarif"
            v-bind:placeholder="placeholder"
            readonly
            disabled
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          />  
          <vuetify-money
            v-model="dokumenBupot.pph_amount"
            label="PPh"
            v-bind:placeholder="placeholder"
            readonly
            disabled
            v-bind:outlined="outlined"
            v-bind:clearable="clearable"
            v-bind:valueWhenIsEmpty="valueWhenIsEmpty"
            v-bind:options="options"
          /> 
        </v-card-text>
      </v-card>
      </v-col>
      <v-col
        :cols="8"
      >
        <v-card class="my-5 mx-2">
            <v-card-text>
            <v-toolbar flat>
                <v-toolbar-title>Dokumen Kwitansi</v-toolbar-title>
            </v-toolbar>
            <v-divider></v-divider>        
            <v-data-table
              :headers="headersKwitansi"
              :items="dokumenKwt"
              class="elevation-1 my-5"
            >
              <template v-slot:[`item.dpp_amount`]="{ item }">
                {{formatCurrency(item.dpp_amount)}}
              </template>
              <template v-slot:[`item.ppn_amount`]="{ item }">
                {{formatCurrency(item.ppn_amount)}}
              </template>
              <template v-slot:[`item.pph_amount`]="{ item }">
                {{formatCurrency(item.pph_amount)}}
              </template>
            </v-data-table>         
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-card>
    </v-dialog>
    <v-card-title>
      Cancel Refund
      <br>
      <v-row class="ml-1">
        <v-col
        :cols="4">
        <v-text-field
          v-model="cariData.nomor_bupot"
          label="Nomor Bukti Potong"
        ></v-text-field>
        </v-col>
        <v-col
        :cols="4">
        <v-text-field v-model="cariData.tanggal_awal" single-line label="Dari Tanggal" readonly>
          <template v-slot:append-outer>
            <date-picker v-model="cariData.tanggal_awal"/>
          </template>
        </v-text-field>                   
        </v-col>
        <v-col
        :cols="4">
        <v-text-field v-model="cariData.tanggal_akhir" single-line label=" Sampai Tanggal" readonly>
          <template v-slot:append-outer>
            <date-picker v-model="cariData.tanggal_akhir"/>
          </template>
        </v-text-field>        
        </v-col>
      </v-row>
        <v-btn
          color="success"
          dark
          class="mb-2"
          @click="searchData"
        >
          Cari Data
        </v-btn>        
        <v-btn
          color="error"
          dark
          class="mb-2 ml-2"
          @click="resetData"
        >
          Reset Data
        </v-btn>  
    </v-card-title>
  <v-divider></v-divider>
        <v-btn
          color="error"
          dark
          class="mb-4 ml-4 mt-4"
          @click="updateCancel"
          x-large
        >
          Cancel
        </v-btn>       
  <v-divider></v-divider>  
  <img id='barcode3' style="display:none;"/>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="inquiryBupot"
    item-key="refund_id"
    class="elevation-1"
    show-select
  >
    <template v-slot:[`item.refund_number`]="{ item }">
        <!-- <router-link :to="{name: 'DetailBupot', params: { id: value }}"> -->                  
        <a @click="showPdf(item)" v-if="item.status === 'D'" class="font-weight-bold">
          {{ item.refund_number }}
        </a>                     
        <!-- </router-link> -->
    </template>
    <template v-slot:[`item.status`]="{ item }">
      <div class="font-weight-bold">
        {{formatValidasi(item.status)}}
      </div>
    </template>
    <template v-slot:[`item.dpp_amount`]="{ item }">
      {{formatCurrency(item.dpp_amount)}}
    </template>
    <template v-slot:[`item.percentage`]="{ item }">
      {{formatCurrency(item.percentage)}}
    </template>
    <template v-slot:[`item.pph_amount`]="{ item }">
      {{formatCurrency(item.pph_amount)}}
    </template>
    <template v-slot:[`item.bupot_date`]="{ item }">
      {{formatDate(item.bupot_date)}}
    </template>
  </v-data-table>
  </v-card>
</v-container>
</template>

<script>
/* eslint-disable */
import DatePicker from "../../../components/DatePicker.vue";
import axios from 'axios'
import {mapGetters} from 'vuex'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import JsBarcode from 'jsbarcode';
  export default {
    data () {
      return {
        idRefund:[],
        data:[],
        value2: "1234567.89",
        label: "Value",
        placeholder: " ",
        readonly: false,
        disabled: false,
        outlined: true,
        clearable: true,
        valueWhenIsEmpty: "0",
        options: {
          locale: "pt-BR",
          prefix: "IDR",
          suffix: "",
          length: 11,
          precision: 0
        },
        properties: {
          hint: "my hint"
          // You can add other v-text-field properties, here.
        },
        customer:[],
        dokumenBupot:{},
        dialogDelete:false,
        cariData:[],
        search:'',
        dialog: false,
        singleSelect: false,
        selected: [],
        selected2: [],
        inquiryBupot:[],
        selectedIndex:0,
        selectedItem:{},
        dokumenKwt:[],
        dokumenKwtarray:[],
        dokumenKwtarray2:[],
        dokumenKwtarray3:[],
        headers: [
          { text: 'Status', value: 'status' },
          { text: 'Nomor Refund', value: 'refund_number' },
          { text: 'Tanggal Refund', value: 'refund_date' },
          { text: 'DPP', value: 'dpp_amount' },
          { text: 'PPh', value: 'pph_amount' },
          { text: 'Jumlah Kwitansi', value: 'JumlahKwitansi' },
        ],
        headersKwitansi: [
          { text: 'Nomor Kwitansi', value: 'kwt_number' },
          { text: 'Tanggal Kwitansi', value: 'kwt_date' },
          { text: 'Jenis Kwitansi', value: 'kwt_type' },
          { text: 'DPP Kwitansi', value: 'dpp_amount' },
          { text: 'PPN Kwitansi', value: 'ppn_amount' },
          { text: 'PPh Kwitansi', value: 'pph_amount' },
        ],
      }
    },
    computed: {
        formTitle () {
          return this.editedIndex === -1 ? 'Tambah Role' : 'Edit Role'
        },
        ...mapGetters({
          user: 'user',
        })
    },
    created () {       
		let uri = `http://localhost:8000/api/trxpagesubmit/${this.$session.get('customer_id')}`;
            axios.get(uri).then(response => {
                this.inquiryBupot = response.data.data;
            });
    },
    methods:{
        searchData(){
            axios({
                method: 'post',
                url: 'http://localhost:8000/api/searchdata',
                data: {
                  nomor_bupot: this.cariData.nomor_bupot,
                  tanggal_awal: this.cariData.tanggal_awal,
                  tanggal_akhir: this.cariData.tanggal_akhir,
                  cust_id: this.user.customer_id
                },
              })
               .then(response => {
                 this.inquiryBupot = response.data.data;
                //  console.log(this.inquiryBupot)
                })
                .catch(error => {
                  console.log(error.response)

                })          
        },
        resetData(){
            this.cariData = [];
            let uri = `http://localhost:8000/api/trxpagesubmit/${this.user.customer_id}`;
                axios.get(uri).then(response => {
                    this.inquiryBupot = response.data.data;
                });          
        },
        formatCurrency2(value){
            var formatter = new Intl.NumberFormat('en-US', {
            });

            return formatter.format(value); /* $2,500.00 */          
        },
        formatCurrency(value){
            var formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'IDR',
            });

            return formatter.format(value); /* $2,500.00 */          
        },
        formatValidasi(value){
            if (value == "D"){
              return "Draft"
            } else if (value == "S"){
              return "Submit"
            } else if (value == "V"){
              return "Valid"
            } else if (value == "R"){
              return "Reject"
            } else if (value == "C"){
              return "Cancel"
            }
        },
        showDialog(item){
        let uri = `http://localhost:8000/api/dtltrxpage/${item.bupot_id}`;
            axios.get(uri).then(response => {
                this.dokumenBupot = response.data.data;
            });        
        let uri2 = `http://localhost:8000/api/dtlKwtpage/${item.bupot_id}/${this.user.customer_id}`;
            axios.get(uri2).then(response => {
                this.dokumenKwt = response.data.data;
            });         
          this.dialogDelete = true
        },
        closeDialog(){
          this.dialogDelete = false
          this.dokumenBupot = {}
          this.dokumenKwt = []
        },     
        updateCancel (){
          if(this.selected.length == 0){
              this.$swal.fire(
              'Peringatan!',
              'Silahkan Pilih Bukti Potong yang mau dicancel!',
              'warning'
            )
            }else{
              this.idRefund.splice(0);
              this.selected.forEach(item => {
                  this.idRefund.push(item.refund_id)
              })
              this.$swal.fire({
                title: 'Apakah anda yakin ingin cancel data ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ya',
                cancelButtonText: 'Tidak'
              }).then((result) => {
                if (result.isConfirmed) {
                  axios({
                      method: 'post',
                      url: 'http://localhost:8000/api/updatestatusrefundcancel',
                      data: {
                        id_refund: this.idRefund
                      },
                    })
                     .then(response => {
                        this.$swal.fire(
                          'Sukses!',
                          'Data berhasil di cancel!',
                          'success'
                        )                    
                        this.$router.push({ name: "TrxPage" }).catch(() => {});
                        // console.log(response)
                      })
                      .catch(error => {
                        console.log(error.response)
                      })
                }
              }) 
            }
        },
        showPdf(item){
          JsBarcode("#barcode3", item.refund_number);          
          this.dokumenKwtarray3.splice(0);
          var sum = 0;
          var sumb = 0;
          var sumc = 0;
          var i = 1;
          var sumTotalDppRefund = 0;
          var sumTotalPPhRefund = 0;
          let uri = `http://localhost:8000/api/getcustnumberandname/${this.$session.get('customer_id')}`;
            axios.get(uri).then(response => {
              this.customer = response.data.data;
            }); 
          let uri2 = `http://localhost:8000/api/getkwtarray/${item.refund_id}/${this.$session.get('customer_id')}`;
            axios.get(uri2).then(response => {
              this.dokumenKwtarray = response.data.data;
              try {
                this.dokumenKwtarray2=this.dokumenKwtarray.map(this.getKwtValue)
              }
              catch(err) {
                console.log(err);
              }
              this.data=this.dokumenKwtarray
              this.data.forEach(item => {
                this.dokumenKwtarray3.push({
                  bupot_number:item.bupot_number,
                  bupot_date:item.bupot_date,
                  dpp_amount_bupot:this.formatCurrency2(item.dpp_amount_bupot),
                  pph_amount_bupot:this.formatCurrency2(item.pph_amount_bupot),
                  kwt_id:item.kwt_id,
                  kwt_number:item.kwt_number,
                  kwt_date:this.formatDate(item.kwt_date),
                  dpp_amount:this.formatCurrency2(item.dpp_amount),
                  ppn_amount:this.formatCurrency2(item.ppn_amount),
                  pph_amount:this.formatCurrency2(item.pph_amount)
                })
              })
              this.dokumenKwtarray.forEach(item => {
                sum = sum + parseInt(item.dpp_amount);
              })
              this.dokumenKwtarray.forEach(item => {
                sumb = sumb + parseInt(item.ppn_amount);
              })  
              this.dokumenKwtarray.forEach(item => {
                sumc = sumc + parseInt(item.pph_amount);
              })              
              sum = this.formatCurrency2(sum);
              sumb = this.formatCurrency2(sumb);
              sumc = this.formatCurrency2(sumc);
              sumTotalDppRefund = this.formatCurrency2(item.dpp_amount);
              sumTotalPPhRefund = this.formatCurrency2(item.pph_amount);
              try {
                this.dokumenKwtarray3=this.dokumenKwtarray3.map(this.getKwtValue)
              }
              catch(err) {
                console.log(err);
              }        
              var raw = this.dokumenKwtarray3
              var body = []
              var bupotIdonly = []
              var newIdbupotonly =[]
              var index = 1; 
              var count = 0;
              var counts =[];
              var indikator = 1;
              var activeBupot = "";
              raw.forEach(item => [
                bupotIdonly.push({
                  bupotNumber: item[0]
                })
              ])
              newIdbupotonly = bupotIdonly.map(this.getBupotNumber);
              newIdbupotonly.forEach(function (x) { counts[x] = (counts[x] || 0) + 1; });
              var j=0;
              for (i = 0; i < raw.length; i++) {
                // console.log("data ke",i)
                var row = []
                var nextRow = []
                for (var key in raw[i]) {
                  row.push(raw[i][key])
                }
                for (var key2 in raw[i+1]){
                  nextRow.push(raw[i+1][key2])
                }
                if(activeBupot != row[0]) {
                  indikator = 1;
                } 
                if (indikator === 1){
                  activeBupot = row[0];
                  indikator = 0;
                  count = counts[activeBupot];
                  j=0;
                }
                if (j % count === 0){
                  var no_bupot = row[0];
                  var tanggal_bupot = row[1];
                  var total_dpp_bupot = row[2];
                  var total_pph_bupot = row[3];              
                  row.shift();
                  row.shift();
                  row.shift();
                  row.shift();
                  row.unshift({
                    rowSpan: count,
                    content: total_pph_bupot,
                    styles: { valign: 'middle', halign: 'center' },
                  })
                  row.unshift({
                    rowSpan: count,
                    content: total_dpp_bupot,
                    styles: { valign: 'middle', halign: 'center' },
                  })
                  row.unshift({
                    rowSpan: count,
                    content: tanggal_bupot,
                    styles: { valign: 'middle', halign: 'center' },
                  })
                  row.unshift({
                    rowSpan: count,
                    content: no_bupot,
                    styles: { valign: 'middle', halign: 'center' },
                  })
                  row.unshift({
                    rowSpan: count,
                    content: index,
                    styles: { valign: 'middle', halign: 'center' },
                  })
                  j=j+1;
                  index = index + 1;
                }
                else{
                  row.shift();
                  row.shift();
                  row.shift();
                  row.shift();
                  j=j+1
                }
                body.push(row);
                // console.log(body)
              }
              const img = document.querySelector('img#barcode3');
              var cust_name = this.customer.map(({ customer_name }) => customer_name)
              var cust_number = this.customer.map(({ customer_number }) => customer_number)
              var header = cust_number + ' - ' + cust_name;
              // doc.text(header, 13, 5, { baseline: 'middle' });
              var doc = new jsPDF();
              doc.text("Nomor Refund",105,15,{baseline: 'middle',align: 'center'});
              doc.setFontSize(10);
              doc.addImage(img.src, 'JPEG', 55, 25,100,25);
              doc.text(item.refund_number + ' / ' + this.formatDate(item.refund_date),85,23,{baseline: 'middle',align: 'left',lineHeightFactor: '0.5'});
              doc.setFontSize(10);
              doc.text("Customer",166,10,{baseline: 'middle',align: 'left',lineHeightFactor: '0.5'});
              doc.setFontSize(10);
              doc.text(header,166,15,{baseline: 'middle',align: 'left',lineHeightFactor: '0.5'});              
              doc.autoTable({ 
                  columnStyles: {
                    0: {cellWidth:8},
                    1: {cellWidth:25, fontStyle: 'bold', fontSize: 9}, 
                    2: {halign:'center', cellWIdth:25, fontSize: 9},
                    3: {halign:'right', cellWIdth:30, fontSize: 8},
                    4: {halign:'right', fontSize: 8},
                    5: {halign:'right', fontSize: 6,fontStyle: 'bold'},
                    6: {halign:'center', fontSize: 9,cellWidth:20},
                    7: {halign:'right', cellWIdth:20, fontSize: 6},
                    8: {halign:'right', cellWIdth:20, fontSize: 6},
                    9: {halign:'right', cellWIdth:20, fontSize: 6},
                    10:{halign:'right', cellWIdth:30, fontSize: 6},
                  },  
                  bodyStyles : {lineColor: [0, 0 ,0 ]},
                  headerStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor:'black'
                  },
                  footStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0],
                      fillColor: [255, 255, 255],
                      textColor:'black'
                  },                  
                  theme: 'grid',
                  head: [['No','No Bupot','Tgl Bupot','Total DPP Bupot','Total PPH Bupot','No Kwt', 'Tgl Kwt',  'DPP Kwt', 'PPN Kwt', 'PPh Kwt']],
                  body: body,
                  margin: {top: 55},
                  foot: [[
                    {content: 'Grand total', colSpan: 3, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                    {content: sumTotalDppRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 7}},
                    {content: sumTotalPPhRefund, colSpan: 1, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black', fontSize: 7}},
                    {content: 'Grand total Kwt', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}},
                    {content: sum, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 8}},
                    {content: sumb, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 8}},
                    {content: sumc, colSpan: 1, styles: {halign: 'right', fillColor: [255, 255, 255], textColor:'black', fontSize: 8}}
                  ]]
                })
                  doc.autoTable({
                  bodyStyles : {lineColor: [0, 0 ,0 ]},
                  headerStyles: {
                      lineWidth: 0.5,
                      lineColor: [0, 0, 0]
                  },             
                  theme : 'grid',
                  head: [[{content: 'Validation Notes', colSpan: 2, styles: {halign: 'center', fillColor: [255, 255, 255], textColor:'black'}}]],
                  body: [
                    ['Tgl. Validasi Refund:  ', '                          '],
                    ['Tgl. Cetak  Refund:  ', '                          '],
                    ['Tgl. Kembali:  ', '                          '],
                    ['Paraf Petugas Validasi:  ', '                          '],
                    ['Nama Petugas:  ', '                          '],
                  ],
                  margin: {left: 135},
                  startY: 215,
                  lineColor: [0,0,0]
                })
                      // doc.setFontSize(35)
                      // //doc.setTextColor(230);
                      // doc.setTextColor(245, 245, 245, 0.33);
                      // doc.setFont("Boogaloo");
                      // doc.text("Watermark", 60, 200, null, 45);
              // doc.output('dataurlnewwindow')
              doc.setProperties({
                  title: "Laporan Refund"
              });
              window.open(doc.output('bloburl'))
            }); 
          this.dokumenKwtarray2.splice(0);
        },
        getKwtValue(item){
            var data = [item.nomor,item.bupot_number,item.bupot_date,item.dpp_amount_bupot,item.pph_amount_bupot,item.kwt_number,item.kwt_date,item.dpp_amount,item.ppn_amount,item.pph_amount];
            return data;
        },
        formatDate(value){
          return moment(value).format("DD-MM-YYYY");
        },                   
    },
    components: {
      DatePicker
    }
  }
</script>